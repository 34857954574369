// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
.left-aligned{
	text-align: left;
}

.adder.line{
	font-size: 1px;
	td{
		height: 1px;
		position: relative;
		&.hover {
			background: #028bf9;
			transition: all 2s ease;
		}
	}
}

.add-row{
	position: absolute;
	left: 4px;
	top: -17px;
}

.add-row-icon {
	font-size: 12px !important;
	position: absolute;
	left: -14px;
	top: -6px;
}

.remove-row-icon {
	font-size: 12px !important;
	position: absolute;
	left: -14px;
	top: 8px;
}

tr td{
	transition: all 1s ease !important;
	background: inherit;
	&.hover{
		background: #db2828;
		transition: all 1s ease !important;
	}
}

table td span{
	display: block;
	height: 1.2em;
}

.save-anexa{
	width: calc(100% - 1.6em) !important;
}

.anexa3 .ui.table tfoot th{
	font-weight: bold;
}

.ui.steps .step{
	cursor: pointer;
	&:hover{
		background: #f0f0f0;
	}
}

[contenteditable]{
	color: #028bf9;

	&:active, &:focus{
		border:none;
		outline:none;
	}
	&[data-changed="yes"]{
		color: #21ba45;
	}
	&[class*="error"]{
		color: #db2828;
	}
}


.my-table{
	td{
		border: none !important;
	}
	tr:not(:last-child){
		border-bottom: 1px solid rgba(34,36,38,.1);
	}
}

.dropzone{
	cursor: pointer !important;
	min-height: 150px !important;
	padding: 50px !important;
	border: 1px dashed #028bf9 !important;
}

.dz-message span{
	font-weight: 800 !important;
}

.documente .header{
	word-break: break-all;
}

.my-columns{
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	word-break: break-all;
}

.item.avoid{
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}

.ui.right.aligned.segment {
	label, .header, .label, .field {
		text-align:left;
	}
}

.field{
	.ui.pointing.above.label:before, .ui.pointing.label:before{
		left: 10% !important;
	}
	.prompt.label{
		text-align: left;
		white-space: normal !important;
	}
}

.grid.anexa-8{
	.row.header{
		background: #eee;
		font-weight: bold;
		.segment{
			text-align: center;
		}
	}
	.column{
		align-self:stretch !important;
		padding: 0 !important;
	}
	.segment{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: left;
		padding: 1em;
	}
}
.anexa8{
	word-wrap: break-word;
	width: 100%;
	table-layout:fixed;
	ol{
		margin:0;
		padding-left:1em;
	}
	th.small{
		width:50px;
	}
	th.medium{
		width: 110px;
	}
}

.anexa8-holder{
	max-height: calc(100vh - 100px);
	overflow: auto;
}

.lista-contracte{
	span{
		display: inline;
		height: auto;
	}
}

i.icon{
	transition: all .2s ease;
	&:before{
		transition: all .2s ease;
	}
}

.card.rgba{
	&.blue{
		background: rgba(33, 133, 208, .1);
	}
	&.olive{
		//background: rgba(100, 53, 201, .05);
		background: rgba(181, 204, 24, .1);
	}
}

tr td.got-focus {
	background-color: rgba(0, 181, 173, .1) !important;
	transition: all .01s !important;
}

.no-margin-bottom{
	margin-bottom: .5em !important;
}

.anexa3 caption{
	text-align: left;
  font-weight: bold;
  margin: 1em inherit;
}

.segment.tab{
	overflow: auto;
}

.text-style{
	width:100%;
	resize: vertical;
	background: transparent;
	border:none;

	&::-webkit-scrollbar{
	  background: transparent;
	  width: .75em;
	  height: .75em;
	}

	&::-webkit-scrollbar-thumb {
	  background: rgba(0,159,218,.5);
	  border-radius: 10px;
	}
	&::-webkit-scrollbar-track{
	  border: thin solid rgba(0,159,218,.5);
	  border-radius: 10px;
	  margin-bottom: 1em;
	}
}

.toast-message a{
	display: block;
}

.ui.pagination.menu{
	flex-wrap: wrap;
}

.break-word{
	white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
	white-space: -webkit-pre-wrap; /*Chrome & Safari */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	white-space: pre-wrap;       /* css-3 */
	word-wrap: break-word;       /* Internet Explorer 5.5+ */
	word-break: break-all;
	white-space: normal;
}

.popup-arrow{
	max-height: 200px;
	overflow: auto;
	box-sizing: content-box;
	padding: 1.2em !important;
	white-space: nowrap;

	&:before{
		display: none;
	}

	a{
		color: rgba(0,0,0,.4) !important;
	}

	a:hover{
		color: #00b2f3 !important;
	}
}

#vue-container{
	text-align:center;
	// padding: 1em 2em;

	label, .previziuni{
		text-align:left;
	}
}

.dz-preview, .dz-file-preview, .dz-processing, .dz-success, .dz-complete{
	z-index: 1;
}

.no-breaks{
	white-space:nowrap;
}

.settings-dd{
	&.ui.labeled.icon.button{
		padding-left: 1.1em !important;
		min-width: auto;
	}
	.menu{
		left: -150px !important;
	}
}

.me-utils--fixed-header-2{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    // height: calc(100% - 20px);
    overflow: hidden;
    position: relative;

  * {
    box-sizing: border-box;
  }

  thead, tbody{
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    /* border: thin solid blue; */
    /* margin: 2px; */
  }

  thead{
    min-height: min-content;
    flex-shrink: 0;
    /* flex-grow: 2; */
  }

  tbody{
    flex-shrink: 1;
    /* flex-grow: 1; */
    /* height: 80%; */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    backface-visibility: hidden;
    will-change: overflow;

    &::-webkit-scrollbar{
      background: transparent;
      width: .5em;
      // height: .5em;
    }

    &::-webkit-scrollbar-thumb {
      background: #90CAF9;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track{
      border: thin solid #90CAF9;
      border-radius: 10px;
      // margin-bottom: 1em;
    }
  }

  thead tr:after {
    content: '';
    overflow-y: scroll;
    visibility: hidden;
    width: .5em;
    height: 0;
  }

  tr{
    width:100%;
    flex-shrink: 0;
    /* border: thin solid green; */
    display: flex;
    flex-wrap: wrap;
    padding: 2px;
    justify-content: flex-end;
    /* align-items: flex-end; */
    /* align-content: flex-end; */
  }

  th{
  	text-transform: capitalize !important;
  }

  td, th{
    /* border: thin solid orange; */
    // width: calc( (100% - 200px) / 32);
    justify-content: center;
    align-items: center;
    display:flex;
    padding: 2px;
    min-height: min-content;
    min-width: 0;
    width: 2em;
    flex-shrink:1;
    flex-grow:1;
    overflow: hidden;

    &.aligned{
    	&.left{
    		justify-content: flex-start;
    	}

    	&.center{
    		justify-content: center;
    	}

    	&.right{
    		justify-content: flex-end;
    	}
    }

		th.content{
			&::selection{
        background: transparent;
        color: black;
      }
      &::-moz-selection{
        background: transparent;
        color: black;
      }
		}

    .content{
      flex: 1;
      // overflow: hidden;
      // white-space:nowrap;
      height: auto !important;
      word-break: break-word;
      text-overflow: ellipsis;
    }
  }
}

.me-utils--dropdown-menu{
  .menu{
    .item{
      .label{
        width: fit-content;
        flex:200;
      }
      input{
        width: 5em;
        flex:1;
      }
    }
  }
}

.me-utils--admin-table{
  th{
    cursor: pointer !important;
  }
  i.filter:hover{
  	color: #2185d0!important;
  }
  max-height: calc(100vh - 300px);

  .detalii{
		width:100%;
		white-space: pre;
		font-family: monospace;
		font-size:.75em;
		border:none;
		resize: vertical;
		background: transparent;
		border:none;

		&::-webkit-scrollbar{
		  background: transparent;
		  width: .5em;
		  height: .5em;
		}

		&::-webkit-scrollbar-thumb {
		  background: rgba(0,159,218,.5);
		  border-radius: 10px;
		}
		&::-webkit-scrollbar-track{
		  border: thin solid rgba(0,159,218,.5);
		  border-radius: 10px;
		  margin-bottom: 1em;
		  margin-right: 1em;
		}
	}
}

.me-utils--right-aligned{
	text-align:right;
}

.table-executie-holder{

	  // border: 2px solid grey;
	  display: flex;
	  width: 100%;

	  *{
	  	box-sizing: content-box;
	  }

	.fixed{
		font-weight: bold;
		border-right: 2px solid grey;
		width: 25%;
	}

	.scroll{
		width: 75%;
		.cell{
			justify-content:flex-end;
		}
	}

	.fixed, .scroll{
	  // border: thin solid blue;
	  display: flex;
	  // width: 50%;
	  overflow-x: scroll;
	}

	.col{
	  // border: thin solid green;
	  display:flex;
	  flex-direction: column;
	  flex:1
	}

	.cell:first-child{
	  flex:1;
	  font-weight: bold;
	  text-transform: uppercase;
	}

	.cell{
	  border-bottom: thin solid grey;
	  padding: .5em 1em;
	  min-height: 1em;
	  flex:10;
	  white-space: nowrap;
	  // min-height: calc(100% / 11);
	  display:flex;
	  align-items:center;
	}
}

.raport-choice{
	.card{
	  height: 100%;
  .content{
    display: flex;
    align-items: center;
  }
   a{
     width:100%;
   }
	}
}

.modul2{
	td.bold{
		background: rgb(250, 250, 250);
		font-weight: bold;
	}
	tbody{
		th{
			background-color: #fafafa;
		}
	}
}

.segment.generat, .segment.incarcat{
	margin-top: 1em;
	.tabular.menu .item{
		text-align: center;
	}
}

.dz-success-mark{
	background: green;
	border-radius: 100%;
}

.dz-error-mark{
	background: red;
	border-radius: 100%;
}

.me-utils--complex-table{
	width: 100%;
	font-size: 10px;
	margin: 2em auto;
	border: thin solid rgba(34, 36, 38, 0.0980392);


	.bold{
		font-weight: bold;
	}

	td{
		padding: .5em;
	}

	tr{
		border-bottom: thin solid rgba(34, 36, 38, 0.0980392);
	}

	thead, tfoot{
		tr{
			background: rgb(235, 235, 235);
		}
		th{
			padding: 1em;
			text-transform: capitalize;
		}
	}

	.totals{
		background: #f9f9f9;
		td{
			font-weight: bold;
		}
	}

	.aligned{
		&.center{
			text-align: center;
		}
		&.left{
			text-align: left;
		}
		&.right{
			text-align: right;
		}
	}
}

.me-utils--gray-scroll{
	tbody{
	  &::-webkit-scrollbar{
	    background: transparent;
	    width: .5em;
	    // height: .5em;
	  }

	  &::-webkit-scrollbar-thumb {
	    background: rgb(220, 220, 220);
	    border-radius: 10px;
	  }
	  &::-webkit-scrollbar-track{
	    border: thin solid rgb(220, 220, 220);
	    border-radius: 10px;
	    // margin-bottom: 1em;
	  }
	}
}

.ui.checkbox{
	backface-visibility: initial !important;
	-webkit-backface-visibility: initial !important;
}

.me-utils-max-75vh{
	max-height:75vh;
}

tr.luna.proiectie {
	background-color: #FFFAF3!important;
}

.arhiva-height{
  height: calc(100vh - 350px);
  overflow-y: auto;
  min-height: 300px;
}

.arhiva-height-pdf{
  height: calc(100vh - 390px);
  overflow-y: auto;
  min-height: 260px;
}

.modul2.d5 {
  font-size: 1em;
  width: 100%;
  background: #fff;
  margin: 1em 0;
  box-shadow: none;
  text-align: left;
  color: #212121;
  margin-top: 1em;

  .right-aligned {
    text-align: right;
  }

  .center-aligned {
    text-align: center;
  }

  .border-bottom{
    border-bottom: thin solid lighten(#212121, 40%);
  }

  td {
    padding: .5em .7em;
  }

  .blue-bg{
    padding: 1em;
    background-color: lighten(#dedbff, 3%);
  }
  .lightblue-bg{
    padding: 1em;
    background-color: lighten(rgb(33, 133, 208), 45%);
  }
  .lightolive-bg{
    padding: 1em;
    background-color: lighten(rgb(181, 204, 24), 45%);
  }

  tbody{
    &:not(.empty) {
      border: 2px solid #212121;
    }
    &.empty {
      td {
        height: .3em;
        padding:0;
      }
    }
  }

  .bold-text {
    font-weight: bold;
  }

  tbody {
    page-break-inside: avoid !important;

    &.empty {
      page-break-inside: auto !important;
    }

    tr, th, td {
      page-break-inside: avoid !important;
    }
  }
}

.d4-print {
  i {
    display: none;
  }

  td, th {
    text-align: center;
    padding: 1px !important;

    &.right.aligned {
      white-space: nowrap !important;
    }
  }

  thead tr {
    background-color: #f9f9f9 !important;
  }

}

.contract-normal {
  color: lighten(#009fda, 0%);
}
.contract-parinte {
  color: desaturate(#b5cc18, 50%);
}
.contract-copil {
  color: desaturate(#00b5ad, 10%);
}

.copil-bg {
  background-color: lighten(desaturate(#00b5ad, 10%), 60%);
}

.bold-500 {
  font-weight: 500;
}

.display-menu {
  // overflow-x: auto;
  flex-grow: 1;

  .item {
    white-space: nowrap;
    min-width: min-content !important;
    flex-grow: 1 !important;
  }

  .right.menu {
    flex-grow: 5 !important;
      .input {
        display: flex !important;
      }
    }

}

#filter-box {
  width: 3em;
  flex-grow: 1;
}

@media (max-width: 1400px) {
  .hide-sm {
    display: none;
  }
  .filter-closed, .filter-inst {
    .text span{
      display: none !important;
    }
  }
  .display-menu {
    .item {
      padding: .3em !important;
    }
  }
}

@media (max-width: 900px) {
  .hide-md {
    display: none;
  }
}

.field.error {
  i.calendar {
    color: red !important;
  }
}

label.disabled {
  opacity: .45;
}

.table-wrap {
  max-height: 80vh;
  overflow: auto;

  &.st17 {
    padding-bottom: 10em;
  }

  table, tbody, thead {
    background-color: #fafafa;
  }

  thead, tbody, tfoot {
    transform: translate(0, -2px);
  }

  &::-webkit-scrollbar{
    background-color: #6a6b6b;
    width: .5em;
    border-radius: 10px;
    // height: .5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track{
    // border: thin solid rgb(20, 20, 20);
    // border-radius: 10px;
    // margin-bottom: 1em;
  }

}

//  .ui.scrolling.dropdown .menu {
  // max-height: 10em;
//  }