.left-aligned {
  text-align: left; }

.adder.line {
  font-size: 1px; }
  .adder.line td {
    height: 1px;
    position: relative; }
    .adder.line td.hover {
      background: #028bf9;
      transition: all 2s ease; }

.add-row {
  position: absolute;
  left: 4px;
  top: -17px; }

.add-row-icon {
  font-size: 12px !important;
  position: absolute;
  left: -14px;
  top: -6px; }

.remove-row-icon {
  font-size: 12px !important;
  position: absolute;
  left: -14px;
  top: 8px; }

tr td {
  transition: all 1s ease !important;
  background: inherit; }
  tr td.hover {
    background: #db2828;
    transition: all 1s ease !important; }

table td span {
  display: block;
  height: 1.2em; }

.save-anexa {
  width: calc(100% - 1.6em) !important; }

.anexa3 .ui.table tfoot th {
  font-weight: bold; }

.ui.steps .step {
  cursor: pointer; }
  .ui.steps .step:hover {
    background: #f0f0f0; }

[contenteditable] {
  color: #028bf9; }
  [contenteditable]:active, [contenteditable]:focus {
    border: none;
    outline: none; }
  [contenteditable][data-changed="yes"] {
    color: #21ba45; }
  [contenteditable][class*="error"] {
    color: #db2828; }

.my-table td {
  border: none !important; }

.my-table tr:not(:last-child) {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1); }

.dropzone {
  cursor: pointer !important;
  min-height: 150px !important;
  padding: 50px !important;
  border: 1px dashed #028bf9 !important; }

.dz-message span {
  font-weight: 800 !important; }

.documente .header {
  word-break: break-all; }

.my-columns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  word-break: break-all; }

.item.avoid {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid; }

.ui.right.aligned.segment label, .ui.right.aligned.segment .header, .ui.right.aligned.segment .label, .ui.right.aligned.segment .field {
  text-align: left; }

.field .ui.pointing.above.label:before, .field .ui.pointing.label:before {
  left: 10% !important; }

.field .prompt.label {
  text-align: left;
  white-space: normal !important; }

.grid.anexa-8 .row.header {
  background: #eee;
  font-weight: bold; }
  .grid.anexa-8 .row.header .segment {
    text-align: center; }

.grid.anexa-8 .column {
  align-self: stretch !important;
  padding: 0 !important; }

.grid.anexa-8 .segment {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 1em; }

.anexa8 {
  word-wrap: break-word;
  width: 100%;
  table-layout: fixed; }
  .anexa8 ol {
    margin: 0;
    padding-left: 1em; }
  .anexa8 th.small {
    width: 50px; }
  .anexa8 th.medium {
    width: 110px; }

.anexa8-holder {
  max-height: calc(100vh - 100px);
  overflow: auto; }

.lista-contracte span {
  display: inline;
  height: auto; }

i.icon {
  transition: all .2s ease; }
  i.icon:before {
    transition: all .2s ease; }

.card.rgba.blue {
  background: rgba(33, 133, 208, 0.1); }

.card.rgba.olive {
  background: rgba(181, 204, 24, 0.1); }

tr td.got-focus {
  background-color: rgba(0, 181, 173, 0.1) !important;
  transition: all .01s !important; }

.no-margin-bottom {
  margin-bottom: .5em !important; }

.anexa3 caption {
  text-align: left;
  font-weight: bold;
  margin: 1em inherit; }

.segment.tab {
  overflow: auto; }

.text-style {
  width: 100%;
  resize: vertical;
  background: transparent;
  border: none; }
  .text-style::-webkit-scrollbar {
    background: transparent;
    width: .75em;
    height: .75em; }
  .text-style::-webkit-scrollbar-thumb {
    background: rgba(0, 159, 218, 0.5);
    border-radius: 10px; }
  .text-style::-webkit-scrollbar-track {
    border: thin solid rgba(0, 159, 218, 0.5);
    border-radius: 10px;
    margin-bottom: 1em; }

.toast-message a {
  display: block; }

.ui.pagination.menu {
  flex-wrap: wrap; }

.break-word {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;
  /*Chrome & Safari */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal; }

.popup-arrow {
  max-height: 200px;
  overflow: auto;
  box-sizing: content-box;
  padding: 1.2em !important;
  white-space: nowrap; }
  .popup-arrow:before {
    display: none; }
  .popup-arrow a {
    color: rgba(0, 0, 0, 0.4) !important; }
  .popup-arrow a:hover {
    color: #00b2f3 !important; }

#vue-container {
  text-align: center; }
  #vue-container label, #vue-container .previziuni {
    text-align: left; }

.dz-preview, .dz-file-preview, .dz-processing, .dz-success, .dz-complete {
  z-index: 1; }

.no-breaks {
  white-space: nowrap; }

.settings-dd.ui.labeled.icon.button {
  padding-left: 1.1em !important;
  min-width: auto; }

.settings-dd .menu {
  left: -150px !important; }

.me-utils--fixed-header-2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  position: relative; }
  .me-utils--fixed-header-2 * {
    box-sizing: border-box; }
  .me-utils--fixed-header-2 thead, .me-utils--fixed-header-2 tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* border: thin solid blue; */
    /* margin: 2px; */ }
  .me-utils--fixed-header-2 thead {
    min-height: min-content;
    flex-shrink: 0;
    /* flex-grow: 2; */ }
  .me-utils--fixed-header-2 tbody {
    flex-shrink: 1;
    /* flex-grow: 1; */
    /* height: 80%; */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    backface-visibility: hidden;
    will-change: overflow; }
    .me-utils--fixed-header-2 tbody::-webkit-scrollbar {
      background: transparent;
      width: .5em; }
    .me-utils--fixed-header-2 tbody::-webkit-scrollbar-thumb {
      background: #90CAF9;
      border-radius: 10px; }
    .me-utils--fixed-header-2 tbody::-webkit-scrollbar-track {
      border: thin solid #90CAF9;
      border-radius: 10px; }
  .me-utils--fixed-header-2 thead tr:after {
    content: '';
    overflow-y: scroll;
    visibility: hidden;
    width: .5em;
    height: 0; }
  .me-utils--fixed-header-2 tr {
    width: 100%;
    flex-shrink: 0;
    /* border: thin solid green; */
    display: flex;
    flex-wrap: wrap;
    padding: 2px;
    justify-content: flex-end;
    /* align-items: flex-end; */
    /* align-content: flex-end; */ }
  .me-utils--fixed-header-2 th {
    text-transform: capitalize !important; }
  .me-utils--fixed-header-2 td, .me-utils--fixed-header-2 th {
    /* border: thin solid orange; */
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 2px;
    min-height: min-content;
    min-width: 0;
    width: 2em;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden; }
    .me-utils--fixed-header-2 td.aligned.left, .me-utils--fixed-header-2 th.aligned.left {
      justify-content: flex-start; }
    .me-utils--fixed-header-2 td.aligned.center, .me-utils--fixed-header-2 th.aligned.center {
      justify-content: center; }
    .me-utils--fixed-header-2 td.aligned.right, .me-utils--fixed-header-2 th.aligned.right {
      justify-content: flex-end; }
    .me-utils--fixed-header-2 td th.content::selection, .me-utils--fixed-header-2 th th.content::selection {
      background: transparent;
      color: black; }
    .me-utils--fixed-header-2 td th.content::-moz-selection, .me-utils--fixed-header-2 th th.content::-moz-selection {
      background: transparent;
      color: black; }
    .me-utils--fixed-header-2 td .content, .me-utils--fixed-header-2 th .content {
      flex: 1;
      height: auto !important;
      word-break: break-word;
      text-overflow: ellipsis; }

.me-utils--dropdown-menu .menu .item .label {
  width: fit-content;
  flex: 200; }

.me-utils--dropdown-menu .menu .item input {
  width: 5em;
  flex: 1; }

.me-utils--admin-table {
  max-height: calc(100vh - 300px); }
  .me-utils--admin-table th {
    cursor: pointer !important; }
  .me-utils--admin-table i.filter:hover {
    color: #2185d0 !important; }
  .me-utils--admin-table .detalii {
    width: 100%;
    white-space: pre;
    font-family: monospace;
    font-size: .75em;
    border: none;
    resize: vertical;
    background: transparent;
    border: none; }
    .me-utils--admin-table .detalii::-webkit-scrollbar {
      background: transparent;
      width: .5em;
      height: .5em; }
    .me-utils--admin-table .detalii::-webkit-scrollbar-thumb {
      background: rgba(0, 159, 218, 0.5);
      border-radius: 10px; }
    .me-utils--admin-table .detalii::-webkit-scrollbar-track {
      border: thin solid rgba(0, 159, 218, 0.5);
      border-radius: 10px;
      margin-bottom: 1em;
      margin-right: 1em; }

.me-utils--right-aligned {
  text-align: right; }

.table-executie-holder {
  display: flex;
  width: 100%; }
  .table-executie-holder * {
    box-sizing: content-box; }
  .table-executie-holder .fixed {
    font-weight: bold;
    border-right: 2px solid grey;
    width: 25%; }
  .table-executie-holder .scroll {
    width: 75%; }
    .table-executie-holder .scroll .cell {
      justify-content: flex-end; }
  .table-executie-holder .fixed, .table-executie-holder .scroll {
    display: flex;
    overflow-x: scroll; }
  .table-executie-holder .col {
    display: flex;
    flex-direction: column;
    flex: 1; }
  .table-executie-holder .cell:first-child {
    flex: 1;
    font-weight: bold;
    text-transform: uppercase; }
  .table-executie-holder .cell {
    border-bottom: thin solid grey;
    padding: .5em 1em;
    min-height: 1em;
    flex: 10;
    white-space: nowrap;
    display: flex;
    align-items: center; }

.raport-choice .card {
  height: 100%; }
  .raport-choice .card .content {
    display: flex;
    align-items: center; }
  .raport-choice .card a {
    width: 100%; }

.modul2 td.bold {
  background: #fafafa;
  font-weight: bold; }

.modul2 tbody th {
  background-color: #fafafa; }

.segment.generat, .segment.incarcat {
  margin-top: 1em; }
  .segment.generat .tabular.menu .item, .segment.incarcat .tabular.menu .item {
    text-align: center; }

.dz-success-mark {
  background: green;
  border-radius: 100%; }

.dz-error-mark {
  background: red;
  border-radius: 100%; }

.me-utils--complex-table {
  width: 100%;
  font-size: 10px;
  margin: 2em auto;
  border: thin solid rgba(34, 36, 38, 0.0980392); }
  .me-utils--complex-table .bold {
    font-weight: bold; }
  .me-utils--complex-table td {
    padding: .5em; }
  .me-utils--complex-table tr {
    border-bottom: thin solid rgba(34, 36, 38, 0.0980392); }
  .me-utils--complex-table thead tr, .me-utils--complex-table tfoot tr {
    background: #ebebeb; }
  .me-utils--complex-table thead th, .me-utils--complex-table tfoot th {
    padding: 1em;
    text-transform: capitalize; }
  .me-utils--complex-table .totals {
    background: #f9f9f9; }
    .me-utils--complex-table .totals td {
      font-weight: bold; }
  .me-utils--complex-table .aligned.center {
    text-align: center; }
  .me-utils--complex-table .aligned.left {
    text-align: left; }
  .me-utils--complex-table .aligned.right {
    text-align: right; }

.me-utils--gray-scroll tbody::-webkit-scrollbar {
  background: transparent;
  width: .5em; }

.me-utils--gray-scroll tbody::-webkit-scrollbar-thumb {
  background: gainsboro;
  border-radius: 10px; }

.me-utils--gray-scroll tbody::-webkit-scrollbar-track {
  border: thin solid gainsboro;
  border-radius: 10px; }

.ui.checkbox {
  backface-visibility: initial !important;
  -webkit-backface-visibility: initial !important; }

.me-utils-max-75vh {
  max-height: 75vh; }

tr.luna.proiectie {
  background-color: #FFFAF3 !important; }

.arhiva-height {
  height: calc(100vh - 350px);
  overflow-y: auto;
  min-height: 300px; }

.arhiva-height-pdf {
  height: calc(100vh - 390px);
  overflow-y: auto;
  min-height: 260px; }

.modul2.d5 {
  font-size: 1em;
  width: 100%;
  background: #fff;
  margin: 1em 0;
  box-shadow: none;
  text-align: left;
  color: #212121;
  margin-top: 1em; }
  .modul2.d5 .right-aligned {
    text-align: right; }
  .modul2.d5 .center-aligned {
    text-align: center; }
  .modul2.d5 .border-bottom {
    border-bottom: thin solid #878787; }
  .modul2.d5 td {
    padding: .5em .7em; }
  .modul2.d5 .blue-bg {
    padding: 1em;
    background-color: #eceaff; }
  .modul2.d5 .lightblue-bg {
    padding: 1em;
    background-color: #ddedfa; }
  .modul2.d5 .lightolive-bg {
    padding: 1em;
    background-color: #f4f9d0; }
  .modul2.d5 tbody:not(.empty) {
    border: 2px solid #212121; }
  .modul2.d5 tbody.empty td {
    height: .3em;
    padding: 0; }
  .modul2.d5 .bold-text {
    font-weight: bold; }
  .modul2.d5 tbody {
    page-break-inside: avoid !important; }
    .modul2.d5 tbody.empty {
      page-break-inside: auto !important; }
    .modul2.d5 tbody tr, .modul2.d5 tbody th, .modul2.d5 tbody td {
      page-break-inside: avoid !important; }

.d4-print i {
  display: none; }

.d4-print td, .d4-print th {
  text-align: center;
  padding: 1px !important; }
  .d4-print td.right.aligned, .d4-print th.right.aligned {
    white-space: nowrap !important; }

.d4-print thead tr {
  background-color: #f9f9f9 !important; }

.contract-normal {
  color: #009fda; }

.contract-parinte {
  color: #8b9351; }

.contract-copil {
  color: #09aca5; }

.copil-bg {
  background-color: #e9fefd; }

.bold-500 {
  font-weight: 500; }

.display-menu {
  flex-grow: 1; }
  .display-menu .item {
    white-space: nowrap;
    min-width: min-content !important;
    flex-grow: 1 !important; }
  .display-menu .right.menu {
    flex-grow: 5 !important; }
    .display-menu .right.menu .input {
      display: flex !important; }

#filter-box {
  width: 3em;
  flex-grow: 1; }

@media (max-width: 1400px) {
  .hide-sm {
    display: none; }
  .filter-closed .text span, .filter-inst .text span {
    display: none !important; }
  .display-menu .item {
    padding: .3em !important; } }

@media (max-width: 900px) {
  .hide-md {
    display: none; } }

.field.error i.calendar {
  color: red !important; }

label.disabled {
  opacity: .45; }

.table-wrap {
  max-height: 80vh;
  overflow: auto; }
  .table-wrap.st17 {
    padding-bottom: 10em; }
  .table-wrap table, .table-wrap tbody, .table-wrap thead {
    background-color: #fafafa; }
  .table-wrap thead, .table-wrap tbody, .table-wrap tfoot {
    transform: translate(0, -2px); }
  .table-wrap::-webkit-scrollbar {
    background-color: #6a6b6b;
    width: .5em;
    border-radius: 10px; }
  .table-wrap::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
    border-radius: 10px; }
